import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import ChevronBottomIcon from '../assets/icons/chevron-bottom.svg';
import { useKeycloak } from '@react-keycloak/web';
import { S3Client, GetObjectCommand, GetObjectCommandOutput, HeadObjectCommand } from '@aws-sdk/client-s3';
import { ExcalidrawAnnotationElement } from '@excalidraw/excalidraw/element/types';
import { Color, PDFArray, PDFDocument, PDFName, PDFPage, PDFString, rgb, StandardFonts } from "pdf-lib";
import { Readable } from 'stream';

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ExportModal: React.FC<ExportModalProps> = ({ isOpen, onClose }) => {

  const { keycloak } = useKeycloak();
  const userId = keycloak.tokenParsed?.sub;

  const PinpointPdf = '../assets/icons/pinpoint.pdf';

  const options = [
    "Export with all comments",
    "Export with response sheet as doc file",
    "Export only resolved comments",
    "Export only pending comments",
    "Export only open comments",
    "Export only draft comments",
    "Export only specific division comments or those by selected individuals"
  ];

  // Define the type for the checked options state
  type CheckedOptions = {
    [key: string]: boolean;
  };

  // Function to initialize all checkboxes to false
  const initializeCheckedOptions = (): CheckedOptions => {
    return options.reduce((acc: CheckedOptions, option: string) => {
      acc[option] = false;
      return acc;
    }, {});
  };

  // Initialize state to track checked status of each checkbox
  const [checkedOptions, setCheckedOptions] = useState<CheckedOptions>(
    initializeCheckedOptions()
  );

  // Handler for checkbox changes with explicit type annotations
  const handleCheckboxChange = (option: string) => {
    setCheckedOptions((prev) => {
      const newChecked: CheckedOptions = { ...prev, [option]: !prev[option] };

      // If "Export with all comments" is checked, disable specific options
      if (option === "Export with all comments" && newChecked[option]) {
        const optionsToDisable: string[] = [
          "Export only pending comments",
          "Export only open comments",
          "Export only draft comments",
          "Export only specific division comments or those by selected individuals"
        ];

        optionsToDisable.forEach((opt) => {
          newChecked[opt] = false;
        });
      }

      return newChecked;
    });
  };

  // Handler to reset all checkboxes to their initial state
  const handleReset = () => {
    setCheckedOptions(initializeCheckedOptions());
  };

  // Determine if "Export with all comments" is checked
  const isAllCommentsChecked: boolean = checkedOptions["Export with all comments"];

  // Define options to disable when "Export with all comments" is checked
  const optionsToDisable: string[] = [
    "Export only pending comments",
    "Export only open comments",
    "Export only draft comments",
    "Export only specific division comments or those by selected individuals"
  ];

  const [currentSubmission, setCurrentSubmission] = useState<string>('');
  const [availablePages, setAvailablePages] = useState<number[]>([]);
  const [selectedPage, setSelectedPage] = useState<string>('all');
  const awsRegion = process.env.REACT_APP_AWS_REGION;
  const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const awsS3Bucket = process.env.REACT_APP_AWS_S3_BUCKET;

  useEffect(() => {
    const submission = localStorage.getItem('submission');
    const page = localStorage.getItem('page');

    if (submission) {
      setCurrentSubmission(submission);
      findAvailablePages(submission);
    }

    if (page) {
      setSelectedPage(page);
    }
  }, []);

  // const findAvailablePages = (submission: string): void => {
  //   const pages: number[] = [];
  //   let pageIndex = 0;

  //   while (true) {
  //     const key = `${submission}_excalidrawData_${pageIndex}`;
  //     if (localStorage.getItem(key) === null) {
  //       break;
  //     }
  //     pages.push(pageIndex);
  //     pageIndex++;
  //   }

  //   setAvailablePages(pages);
  // };

  const findAvailablePages = async (submission: string): Promise<void> => {
    const pages: number[] = [];
    let pageIndex = 0;
    const maxPage = 20;
    try {
      while (pageIndex >= maxPage) {
        const s3Key = `gollab/excalidrawData/${userId}/${submission}/${pageIndex}`;
        
        try {
          // Check if the object exists in S3
          await s3Client.send(new HeadObjectCommand({
            Bucket: awsS3Bucket!,
            Key: s3Key
          }));
          
          // If we reach here, the page exists
          pages.push(pageIndex);
          pageIndex++;
          
        } catch (error: any) {
          // If we get a 404 error, the page doesn't exist
          if (error.$metadata?.httpStatusCode === 404) {
            break;
          }
          // For other errors, log and break to avoid infinite loops
          console.error(`Error checking page ${pageIndex}:`, error);
          break;
        }
      }
  
      setAvailablePages(pages);
    } catch (error) {
      console.error("Error finding available pages:", error);
      setAvailablePages([]);
    }
  };

  const handlePageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedValue = e.target.value;
    setSelectedPage(selectedValue);
    // localStorage.setItem('page', selectedValue);
  };

  // Initialize the S3 client
  const s3Client = new S3Client({
    region: awsRegion,
    credentials: {
      accessKeyId: awsAccessKeyId!,
      secretAccessKey: awsSecretAccessKey!,
    },
  });

  const handleExport = async (): Promise<string | null> => {
    const s3Key = `gollab/documents/${userId}/${currentSubmission}.pdf`;
    const params = {
      Bucket: awsS3Bucket!,
      Key: s3Key,
    };
  
    try {
      const data = await s3Client.send(new GetObjectCommand(params));
      const pdfBlob = await data.Body?.transformToByteArray();
      
      if (!pdfBlob) {
        throw new Error('Failed to get PDF blob from S3');
      }
      
      const pdfUrl = URL.createObjectURL(new Blob([pdfBlob], { type: 'application/pdf' }));
      return pdfUrl;
    } catch (error) {
      console.error('Error fetching PDF from S3:', error);
      return null;
    }
  };

  // const fetchAnnotations = (pageNumber: number = 0) => {
  //   const savedData = localStorage.getItem(
  //     `${currentSubmission}_excalidrawData_${pageNumber}`
  //   );

  //   let elements: readonly ExcalidrawAnnotationElement[] = [];

  //   if (savedData) {
  //     try {
  //       const parsedData = JSON.parse(savedData);
  //       if (Array.isArray(parsedData) && parsedData.length > 0) {
  //         elements = parsedData;
  //       }
  //     } catch (error) {
  //       console.error("Error parsing saved data:", error);
  //     }
  //   }

  //   const annotations = elements.filter(el => el.type === 'annotation');
  //   return annotations.map(annotation => ({
  //     x: annotation.x,
  //     y: annotation.y,
  //     note: annotation.annotationText,
  //     color: annotation.color,
  //     connectorType: annotation.connectorType,
  //     connectorEnd: 'dot',
  //     annotationSubType: annotation.annotationSubType,
  //     annotationTextOffsetX: annotation.annotationTextOffsetX,
  //     annotationTextOffsetY: annotation.annotationTextOffsetY
  //   }));
  // };

  const fetchAnnotations = async (pageNumber: number = 0) => {
    try {
      const s3Key = `gollab/excalidrawData/${userId}/${currentSubmission}/${pageNumber}`;
      
      const getParams = {
        Bucket: awsS3Bucket!,
        Key: s3Key
      };

      const response: GetObjectCommandOutput = await s3Client.send(new GetObjectCommand(getParams));
      
      if (!response.Body) {
        throw new Error('Response body is empty');
      }

      // Convert the streaming response to text using the proper SDK method
      const bodyContents = await response.Body.transformToString();

      let elements: readonly ExcalidrawAnnotationElement[] = [];

      if (bodyContents) {
        try {
          const parsedData = JSON.parse(bodyContents);
          if (Array.isArray(parsedData) && parsedData.length > 0) {
            elements = parsedData;
          }
        } catch (error) {
          console.error("Error parsing S3 data:", error);
          return [];
        }
      }

      const annotations = elements.filter(el => el.type === 'annotation');
      return annotations.map(annotation => ({
        x: annotation.x,
        y: annotation.y,
        note: annotation.annotationText,
        color: annotation.color,
        connectorType: annotation.connectorType,
        connectorEnd: 'dot',
        annotationSubType: annotation.annotationSubType,
        annotationTextOffsetX: annotation.annotationTextOffsetX,
        annotationTextOffsetY: annotation.annotationTextOffsetY
      }));

    } catch (error) {
      console.error("Error fetching annotations from S3:", error);
      return [];
    }
  };
  
  // Helper function to convert stream to string
  const streamToString = async (stream: ReadableStream | undefined): Promise<string> => {
    if (!stream) return '';
    
    const chunks: Uint8Array[] = [];
    const reader = stream.getReader();
    
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      chunks.push(value);
    }
    
    return new TextDecoder().decode(Buffer.concat(chunks));
  };

  async function addPinpointsToPage(
    pdfDoc: PDFDocument,
    page: PDFPage,
    pinpointPdfDoc: PDFDocument,
    annotations: Array<{ x: number; y: number; note: string; color: string; connectorType: string; 
      connectorEnd: string; annotationSubType: string; annotationTextOffsetX: number; annotationTextOffsetY: number }>,
    pageHeight: number
  ) {
    // First, embed the pinpoint page
    const [pinpointPage] = await pdfDoc.embedPdf(pinpointPdfDoc, [0]);
    const pinpointWidth = 24;
    const pinpointHeight = 28;

    for (const annotation of annotations) {
      // Convert Excalidraw coordinates to PDF coordinates
      const pdfX = annotation.x;
      const pdfY = pageHeight - annotation.y - pinpointHeight;

      page.drawPage(pinpointPage, {
        x: pdfX,
        y: pdfY,
        width: pinpointWidth,
        height: pinpointHeight,
      });
    }
  }

  // Helper function to convert HTML color to RGB values
  function htmlColorToRGB(color: string) {
    // Handle hex colors
    if (color.startsWith('#')) {
      const r = parseInt(color.slice(1, 3), 16) / 255;
      const g = parseInt(color.slice(3, 5), 16) / 255;
      const b = parseInt(color.slice(5, 7), 16) / 255;
      return rgb(r, g, b);
    }
    
    // Handle rgb/rgba colors
    if (color.startsWith('rgb')) {
      const values = color.match(/\d+/g);
      if (values && values.length >= 3) {
        return rgb(
          parseInt(values[0]) / 255,
          parseInt(values[1]) / 255,
          parseInt(values[2]) / 255
        );
      }
    }
    
    // Default to black if color format is not recognized
    return rgb(0, 0, 0);
  }

  // Helper functions for drawing different connector types
  function drawConnector(
    page: PDFPage,
    startX: number,
    startY: number,
    endX: number,
    endY: number,
    connectorType: string,
    color: Color
  ) {
    switch (connectorType) {
      case 'elbow':
        // Draw elbow connector (right angle)
        page.drawLine({
          start: { x: startX, y: startY },
          end: { x: startX, y: endY },
          thickness: 1,
          color: color
        });
        page.drawLine({
          start: { x: startX, y: endY },
          end: { x: endX, y: endY },
          thickness: 1,
          color: color
        });
        break;
      
      case 'curve':
        // Approximate curve using multiple line segments
        const segments = 20;
        let prevX = startX;
        let prevY = startY;
        
        for (let i = 1; i <= segments; i++) {
          const t = i / segments;
          // Quadratic curve approximation
          const currentX = startX + (endX - startX) * t;
          const currentY = startY + (endY - startY) * t + Math.sin(t * Math.PI) * 30; // Adjust curve height with the 30 multiplier
          
          page.drawLine({
            start: { x: prevX, y: prevY },
            end: { x: currentX, y: currentY },
            thickness: 1,
            color: color
          });
          
          prevX = currentX;
          prevY = currentY;
        }
        break;
      
      case 'line':
      default:
        // Straight line
        page.drawLine({
          start: { x: startX, y: startY },
          end: { x: endX, y: endY },
          thickness: 1,
          color: color
        });
    }
  }

  // Helper function to draw connector end markers
  function drawConnectorEnd(
    page: PDFPage,
    x: number,
    y: number,
    endType: string,
    color: Color,
    angle: number
  ) {
    const size = 5; // Size of the end marker

    switch (endType) {
      case 'arrow':
        // Arrow points facing downward with proper rotation
        const localPoints = [
          { x: -size, y: size },  // Left wing (below tip)
          { x: 0, y: 0 },         // Tip
          { x: -size, y: -size }  // Right wing (above tip)
        ];
      
        // Rotate points with 180° adjustment
        const cos = Math.cos(angle);
        const sin = Math.sin(angle);
        
        const rotatedPoints = localPoints.map(point => ({
          x: point.x * cos - point.y * sin + x,
          y: point.x * sin + point.y * cos + y
        }));
      
        // Draw rotated arrow
        page.drawLine({
          start: rotatedPoints[0],
          end: rotatedPoints[1],
          thickness: 1,
          color: color
        });
        page.drawLine({
          start: rotatedPoints[1],
          end: rotatedPoints[2],
          thickness: 1,
          color: color
        });
        break;

      case 'dot':
        // Draw circle
        page.drawCircle({
          x: x,
          y: y,
          size: size / 2,
          color: color
        });
        break;

      // case 'cross':
      //   // Draw X
      //   page.drawLine({
      //     start: { x: x - size/2, y: y - size/2 },
      //     end: { x: x + size/2, y: y + size/2 },
      //     thickness: 1,
      //     color: color
      //   });
      //   page.drawLine({
      //     start: { x: x - size/2, y: y + size/2 },
      //     end: { x: x + size/2, y: y - size/2 },
      //     thickness: 1,
      //     color: color
      //   });
      //   break;
      
      case 'cross':
        break;
    }
  }

  // Helper function to draw annotation shapes
  function drawAnnotationShape(
    page: PDFPage,
    x: number,
    y: number,
    width: number,
    height: number,
    subType: string,
    color: Color
  ) {
    switch (subType) {
      case 'Rectangle':
        page.drawRectangle({
          x,
          y,
          width,
          height,
          borderColor: color,
          borderWidth: 1,
          color: rgb(1, 1, 1) // Semi-transparent white fill
        });
        break;

      case 'Circle':
        page.drawEllipse({
          x: x + width/2,
          y: y + height/2,
          xScale: width/2,
          yScale: height/2,
          borderColor: color,
          borderWidth: 1,
          color: rgb(1, 1, 1)
        });
        break;

      case 'Cloud':
        // Approximate cloud shape using multiple circles
        const radius = Math.min(width, height) / 4;
        const centers = [
          { x: x + radius, y: y + height - radius },
          { x: x + width/2, y: y + height - radius },
          { x: x + width - radius, y: y + height - radius },
          { x: x + width - radius, y: y + height/2 },
          { x: x + width - radius, y: y + radius },
          { x: x + width/2, y: y + radius },
          { x: x + radius, y: y + radius }
        ];

        centers.forEach(center => {
          page.drawCircle({
            x: center.x,
            y: center.y,
            size: radius,
            borderColor: color,
            borderWidth: 1,
            color: rgb(1, 1, 1)
          });
        });
        break;
    }
  }


  async function drawAnnotationsOnPage(
    pdfDoc: PDFDocument, // Add pdfDoc as a parameter
    page: PDFPage,
    annotations: Array<{ x: number; y: number; note: string; color: string; connectorType: string; 
      connectorEnd: string; annotationSubType: string; annotationTextOffsetX: number; annotationTextOffsetY: number }>,
    pageHeight: number
  ) {
    const fontSize = 12;
    const padding = 5;
    const lineWidth = 1;
  
    // Embed a standard font (Helvetica)
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  
    for (const annotation of annotations) {
/*
      // Convert coordinates
      const startX = annotation.x - 190;
      const startY = pageHeight - annotation.y + 92;
*/  

      // Convert HTML color to RGB values
      const annotationColor = htmlColorToRGB(annotation.color);

      // Calculate positions using offsets
      const startX = annotation.x - 190;
      const startY = pageHeight - annotation.y + 92;
      const textX = startX + annotation.annotationTextOffsetX - 150;
      const textY = startY + annotation.annotationTextOffsetY + 192;

      // In drawAnnotationsOnPage function
      const deltaX = textX - startX;
      const deltaY = textY - startY;
      const angle = Math.atan2(deltaY, deltaX); // Correct angle calculation

      // Create annotation dictionary
      const annotationDict = {
        Type: PDFName.of('Annot'),
        Subtype: PDFName.of('Text'),
        F: 4,
        Contents: PDFString.of(annotation.note),
        Rect: [textX, textY - 20, textX + 200, textY + 20],
        C: [0, 0, 0],
        T: PDFString.of('Comment'),
        M: PDFString.of(new Date().toISOString()),
        Open: true
      };

      // Create PDF object from the dictionary
      const pdfAnnotation = pdfDoc.context.obj(annotationDict);

      // Get existing annotations array using PDFName
      const existingAnnots = page.node.lookup(PDFName.of('Annots'));

      if (!existingAnnots) {
        // Create new annotations array if none exists
        const annotationsArray = pdfDoc.context.obj([pdfAnnotation]);
        page.node.set(PDFName.of('Annots'), annotationsArray);
      } else if (existingAnnots instanceof PDFArray) {
        // Append to existing annotations array
        existingAnnots.push(pdfAnnotation);
      } else {
        // Handle case where existing annotations is not an array
        const annotationsArray = pdfDoc.context.obj([pdfAnnotation]);
        page.node.set(PDFName.of('Annots'), annotationsArray);
      }
      
      // Draw the connector
      drawConnector(
        page,
        startX,
        startY,
        textX,
        textY,
        annotation.connectorType,
        annotationColor
      );

      // Draw connector end marker
      drawConnectorEnd(
        page,
        startX,
        startY,
        annotation.connectorEnd,
        annotationColor,
        angle + Math.PI // Calculate angle based on connector direction if needed
      );

      // Draw annotation text
      const text = annotation.note;
      const textWidth = font.widthOfTextAtSize(text, fontSize);
      const textHeight = fontSize + padding * 2;

      // Draw annotation shape if not Label type
      if (annotation.annotationSubType !== 'Label') {
        drawAnnotationShape(
          page,
          textX,
          textY - textHeight,
          textWidth + padding * 2,
          textHeight,
          annotation.annotationSubType,
          annotationColor
        );
      }

      // Draw text box
      page.drawRectangle({
        x: textX,
        y: textY - textHeight + 10,
        width: textWidth + padding * 2,
        height: textHeight,
        borderWidth: lineWidth,
        borderColor: annotationColor,
        color: rgb(1, 1, 1), // White background
      });

      // Draw the text
      page.drawText(text, {
        x: textX + padding,
        y: textY - textHeight + 10 + padding,
        size: fontSize,
        font: font,
        color: annotationColor
      });
/*  
      // Draw connector line
      page.drawLine({
        start: { x: startX, y: startY },
        end: { x: startX + 50, y: startY - 30 },
        thickness: lineWidth,
        color: annotationColor,
      });
  
      // Calculate text dimensions
      const text = annotation.note;
      const textWidth = font.widthOfTextAtSize(text, fontSize); // Use embedded font's width calculation
      const textHeight = fontSize + padding * 2;      
  
      // Draw text box
      page.drawRectangle({
        x: startX + 50,
        y: startY - 30 - textHeight,
        width: textWidth + padding * 2,
        height: textHeight,
        borderWidth: lineWidth,
        borderColor: annotationColor,
        color: rgb(1, 1, 1), // White background
      });
  
      // Draw annotation text
      page.drawText(text, {
        x: startX + 50 + padding,
        y: startY - 30 - textHeight + padding,
        size: fontSize,
        font: font, // Use the embedded font
        color: annotationColor,
      });
*/      
    }
  }

  const handleExportClick = async () => {
    try {
      const pdfUrl = await handleExport();
      if (!pdfUrl) {
        throw new Error('Failed to get PDF URL');
      }

      const mainPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(mainPdfBytes);

      // console.log("selectedPage", selectedPage);

      if (selectedPage === 'all') {
        // console.log("all page selected");

        for (let i = 0; i < availablePages.length; i++) {
          // const pageAnnotations = fetchAnnotations(i);
          const pageAnnotations = await fetchAnnotations(i);
          const pdfPage = pdfDoc.getPages()[i];
          // console.log("pageAnnotations", pageAnnotations.length);
          if (pageAnnotations.length > 0) {
            await drawAnnotationsOnPage(
              pdfDoc,
              pdfPage,
              pageAnnotations,
              pdfPage.getHeight()
            );
          }
        }
      } else {
        const pageNumber = parseInt(selectedPage.replace("page", ""), 10) - 1;
        const pageAnnotations = await fetchAnnotations(pageNumber);
        const pdfPage = pdfDoc.getPages()[pageNumber];

        // console.log("particular page selected", pageNumber);
        
        if (pageAnnotations.length > 0) {
          await drawAnnotationsOnPage(
            pdfDoc,
            pdfPage,
            pageAnnotations,
            pdfPage.getHeight()
          );
        }
      }

      const updatedPdfBytes = await pdfDoc.save();
      const blob = new Blob([updatedPdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "annotated_document.pdf";
      link.click();

    } catch (error) {
      console.error('Error processing PDF:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Advanced Export" size="semi-full">
      

        {/* Main Content */}
        <div className="space-y-8 px-6">
          
          {/* Export Type Selection */}
          <div className="space-y-4">
          {/* Title Section */}
          <div className="grid grid-cols-3 gap-6 text-neutral-700">            
              <span>Export as</span>
              <span>Pages</span>
          </div>

          {/* Buttons Section */}
          <div className="grid grid-cols-3 gap-6">
              {/* First Button */}
              <div className="items-center">
                <div className="grid grid-cols-1 gap-0">
                  <select
                    id="export-as"
                    name="export-as"
                    autoComplete="export-as"
                    className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 font-medium text-neutral-700 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-emerald-600 sm:text-sm/6 px-5 py-3"
                  >
                    <option>PDF format</option>
                  </select>
                  <img
                    src={ChevronBottomIcon}
                    alt="Chevron Bottom"
                    className="w-2 h-2 pointer-events-none col-start-1 row-start-1 mr-2 self-center justify-self-end text-gray-500"
                  />
                </div>
              </div>

              {/* Second Button */}
              <div className="items-center">
                <div className="grid grid-cols-1 gap-0">
                  <select
                    id="pages"
                    name="pages"
                    value={selectedPage}
                    onChange={handlePageChange}
                    autoComplete="pages"
                    className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 font-medium text-neutral-700 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-emerald-600 sm:text-sm/6 px-5 py-3"
                  >
                    <option value="all">All Pages</option>
                    {availablePages.map((pageNum) => (
                      <option key={pageNum} value={`page${pageNum + 1}`}>
                        Page {pageNum + 1}
                      </option>
                    ))}
                  </select>
                  <img
                    src={ChevronBottomIcon}
                    alt="Chevron Bottom"
                    className="w-2 h-2 pointer-events-none col-start-1 row-start-1 mr-2 self-center justify-self-end text-gray-500"
                  />
                </div>
              </div>
            
            </div>
          </div>


          {/* Export Options Grid */}
          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              "Export with all comments",
              "Export with response sheet as doc file",
              "Export only resolved comments",
              "Export only pending comments",
              "Export only open comments",
              "Export only draft comments",
              "Export only specific division comments or those by selected individuals"
            ].map((option, index) => (
              <div
                  key={index}
                  className={`flex items-start gap-3 ${
                    option.length > 50 ? 'col-span-1 md:col-span-2 lg:col-span-3' : ''
                  }`}
                >
                <div className="w-5 h-5 border-2 border-neutral-300 rounded flex-shrink-0" />
                <span className="text-neutral-700 break-words">
                  {option}
                </span>
              </div>
            ))}
          </div> */}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {options.map((option, index) => {
              // Generate a unique identifier for each checkbox
              const checkboxId = `export-option-${index}`;

              // Determine if this checkbox should be disabled
              const isDisabled = isAllCommentsChecked && optionsToDisable.includes(option);

              return (
                <div
                  key={index}
                  className={`flex items-start gap-3 ${
                    option.length > 50 ? 'col-span-1 md:col-span-2 lg:col-span-3' : ''
                  }`}
                >
                  {/* Custom Checkbox Component */}
                  <div className="flex h-6 shrink-0 items-center">
                    <div className="group grid size-4 grid-cols-1">
                      <input
                        checked={checkedOptions[option]}
                        onChange={() => handleCheckboxChange(option)}
                        id={checkboxId}
                        name={checkboxId}
                        type="checkbox"
                        aria-describedby={`${checkboxId}-description`}
                        disabled={isDisabled}
                        className={`col-start-1 row-start-1 appearance-none rounded border ${
                          isDisabled ? 'border-gray-300 bg-gray-100 cursor-not-allowed' : 'border-gray-300 bg-white'
                        } checked:border-emerald-600 checked:bg-emerald-600 indeterminate:border-emerald-600 indeterminate:bg-emerald-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 disabled:cursor-not-allowed forced-colors:appearance-auto`}
                      />
                      <svg
                        fill="none"
                        viewBox="0 0 14 14"
                        className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                      >
                        <path
                          d="M3 8L6 11L11 3.5"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="opacity-0 group-has-[:checked]:opacity-100"
                        />
                        <path
                          d="M3 7H11"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="opacity-0 group-has-[:indeterminate]:opacity-100"
                        />
                      </svg>
                    </div>
                  </div>

                  {/* Label and Description */}
                  <div className="text-md">
                    <label htmlFor={checkboxId} className={`cursor-pointer ${
                        isDisabled ? 'text-neutral-500 cursor-not-allowed' : 'text-neutral-700'
                      }`}
                    >
                      {option}
                    </label>
                    {/* Optional Description - Adjust if needed */}
                    {/* <p id={`${checkboxId}-description`} className="text-gray-500">
                      Get notified when someone posts a comment on a posting.
                    </p> */}
                  </div>
                </div>
              );
            })}
          </div>

          {/* Action Buttons */}
          <div className="flex gap-4 pt-8">
            <button className="px-8 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors" onClick={handleExportClick}>
              Export
            </button>
            <button className="px-8 py-3 bg-white text-neutral-700 border border-zinc-300 rounded-lg hover:bg-gray-50 transition-colors"
            onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </div>
      
    </Modal>
  );
};

export default ExportModal;