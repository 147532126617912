import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { useKeycloak } from '@react-keycloak/web';

const InitialRedirect: React.FC = () => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  
  const userId = keycloak.tokenParsed?.sub;

  const awsRegion = process.env.REACT_APP_AWS_REGION;
  const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const awsS3Bucket = process.env.REACT_APP_AWS_S3_BUCKET;

  // Initialize the S3 client
  const s3Client = new S3Client({
    region: awsRegion,
    credentials: {
      accessKeyId: awsAccessKeyId!,
      secretAccessKey: awsSecretAccessKey!,
    },
  });

  useEffect(() => {
    const checkSubmissionAndRedirect = async () => {
      try {
        const submission = localStorage.getItem('submission');
        const page = localStorage.getItem('page');

        // if (!submission) {
        //   navigate('/documents');
        //   return;
        // }

        if (!submission) {
          try {
            // Check if the first page exists in S3
            const s3Key = `gollab/excalidrawData/${userId}/submission1/0`;
            await s3Client.send(
              new GetObjectCommand({
                Bucket: awsS3Bucket!,
                Key: s3Key
              })
            );

            // If the object exists, navigate to playground
            navigate('/playground', { state: { submissionKey: 'submission1', page: 'page1' } });
          } catch (s3Error: any) {
            // If the object doesn't exist (404 error), redirect to documents
            if (s3Error.$metadata?.httpStatusCode === 404) {
              navigate('/documents');
            } else {
              // Log other S3 errors and default to documents
              console.error('Error checking S3 submission:', s3Error);
              navigate('/documents');
            }
          }
        } else {
          // For other submissions, proceed to playground
          navigate('/playground', { state: { submissionKey: submission, page } });
        }
      } catch (error) {
        console.error('Redirection error:', error);
        navigate('/documents');
      }
    };

    checkSubmissionAndRedirect();
  }, [navigate]);

  return null;
};

export default InitialRedirect;