import React, { useState, useEffect, CSSProperties } from 'react';
import { ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage } from 'react-compare-slider';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ChevronBottomIcon from '../assets/icons/chevron-bottom.svg';
import PlusIcon from '../assets/icons/plus.svg';
import MinusIcon from '../assets/icons/minus.svg';
import FitToScreenIcon from '../assets/icons/fit-to-screen.svg';
import CrossIcon from '../assets/icons/cross.svg';
import { useNavigate } from 'react-router-dom';
import { GetObjectCommand, HeadObjectCommand, ListObjectsV2Command, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';

type SelectionOption = {
  submission: string;
  page: string;
  label: string;
};

const Comparison: React.FC = () => {
  const [currentSelection, setCurrentSelection] = useState<string>('');
  const [compareSelection, setCompareSelection] = useState<string>('');
  const [selectionOptions, setSelectionOptions] = useState<SelectionOption[]>([]);
  const [leftImage, setLeftImage] = useState('');
  const [rightImage, setRightImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(100);

  const [loaded, setLoaded] = React.useState(0);
  const imageStyle: CSSProperties = {
    opacity: loaded === 2 ? 1 : 0,
    transition: 'opacity 1s 0.5s ease-in-out'
  };

  const awsRegion = process.env.REACT_APP_AWS_REGION;
  const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const awsS3Bucket = process.env.REACT_APP_AWS_S3_BUCKET;
  const imgAlignUrl = process.env.REACT_APP_IMG_ALIGN_URL;

  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const userId = keycloak.tokenParsed?.sub;

  const handleCloseButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent slider interaction
    navigate('/playground');
  };

  // Custom handle component for the slider
  const CustomHandle: React.FC = () => (
    <div
    // This outer <div> is treated as the handle
    style={{
      position: 'relative',
      width: '40px',     // Handle thickness
      height: '100%',    // Fill the slider’s full height
      cursor: 'pointer', // Show pointer when hovering
    }}
  >
    {/* 1) The full-height vertical line */}
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '0.3rem',        
        background:
          'rgba(0,108,0,1)',
      }}
    />

    {/* 2) Center circle with arrows */}
    <button
    type="button"
    style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '48px',
        height: '48px',
        borderRadius: '50%',
        backgroundColor: 'green',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '6px',
        border: '2px solid white',
        cursor: 'pointer',
    }}
    >
    {/* Left triangle (arrow) */}
    <div
        style={{
        width: 0,
        height: 0,
        borderTop: '6px solid transparent',
        borderRight: '10px solid white',
        borderBottom: '6px solid transparent',
        }}
    />

    {/* Right triangle (arrow) */}
    <div
        style={{
        width: 0,
        height: 0,
        borderTop: '6px solid transparent',
        borderLeft: '10px solid white',
        borderBottom: '6px solid transparent',
        }}
    />
    </button>

    {/* 3) Cross icon at the bottom */}
    <button
      type="button"
      onClick={handleCloseButtonClick}
      style={{
        position: 'absolute',
        bottom: '5rem',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        backgroundColor: 'green',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '6px',
        border: '2px solid white',
        cursor: 'pointer',
      }}
    >
      {/* Your custom cross icon (inline SVG for demo) */}
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      >
        <path d="M18 6L6 18M6 6l12 12" />
      </svg>
    </button>
  </div>
  );

  // Generate label for dropdown options
  const formatOptionLabel = (submission: string, page: string): string => {
    const submissionNum = submission.replace('submission', '');
    const pageNum = page.replace('page', '');
    return `Submission ${submissionNum} - Page ${pageNum}`;
  };

  // Initialize options on component mount
  // useEffect(() => {
  //   const options: SelectionOption[] = [];
  //   let submissionCount = 1;

  //   while (localStorage.getItem(`submission${submissionCount}_excalidrawData_0`)) {
  //     let pageCount = 0;
  //     while (localStorage.getItem(`submission${submissionCount}_excalidrawData_${pageCount}`)) {
  //       const submission = `submission${submissionCount}`;
  //       const page = `page${pageCount + 1}`;
  //       options.push({
  //         submission,
  //         page,
  //         label: formatOptionLabel(submission, page)
  //       });
  //       pageCount++;
  //     }
  //     submissionCount++;
  //   }

  //   setSelectionOptions(options);

  //   // Set initial current selection from localStorage
  //   const storedSubmission = localStorage.getItem('submission');
  //   const storedPage = localStorage.getItem('page');
  //   if (storedSubmission && storedPage) {
  //     const initialSelection = `${storedSubmission}-${storedPage}`;
  //     setCurrentSelection(initialSelection);
  //   }
  // }, []);

  useEffect(() => {
    const fetchSubmissionsAndPages = async () => {
      try {
        // List all objects in the user's excalidraw data directory
        const listParams = {
          Bucket: awsS3Bucket!,
          Prefix: `gollab/excalidrawData/${userId}/`
        };
  
        const response = await s3Client.send(new ListObjectsV2Command(listParams));
  
        if (!response.Contents) {
          console.log('No data found in S3');
          return;
        }
  
        // Process S3 paths to extract submission and page information
        const options: SelectionOption[] = [];
        const submissionPageMap = new Map<string, Set<number>>();
  
        response.Contents.forEach(item => {
          if (item.Key) {
            // Extract submission and page numbers from the S3 path
            const matches = item.Key.match(/\/submission(\d+)\/(\d+)$/);
            if (matches) {
              const submissionNum = parseInt(matches[1]);
              const pageNum = parseInt(matches[2]);
              const submission = `submission${submissionNum}`;
  
              // Track pages for each submission
              if (!submissionPageMap.has(submission)) {
                submissionPageMap.set(submission, new Set());
              }
              submissionPageMap.get(submission)?.add(pageNum);
            }
          }
        });
  
        // Convert the mapped data into selection options
        submissionPageMap.forEach((pages, submission) => {
          Array.from(pages).sort((a, b) => a - b).forEach(pageNum => {
            const page = `page${pageNum + 1}`;
            options.push({
              submission,
              page,
              label: formatOptionLabel(submission, page)
            });
          });
        });
  
        // Sort options by submission and page numbers
        options.sort((a, b) => {
          const submissionA = parseInt(a.submission.replace('submission', ''));
          const submissionB = parseInt(b.submission.replace('submission', ''));
          if (submissionA !== submissionB) {
            return submissionA - submissionB;
          }
          const pageA = parseInt(a.page.replace('page', ''));
          const pageB = parseInt(b.page.replace('page', ''));
          return pageA - pageB;
        });
  
        setSelectionOptions(options);
  
        // Set initial current selection from localStorage
        const storedSubmission = localStorage.getItem('submission');
        const storedPage = localStorage.getItem('page');
        if (storedSubmission && storedPage) {
          const initialSelection = `${storedSubmission}-${storedPage}`;
          setCurrentSelection(initialSelection);
        }
  
      } catch (error) {
        console.error('Error fetching submissions and pages:', error);
      }
    };
  
    fetchSubmissionsAndPages();
  }, []);

  const s3Client = new S3Client({
    region: awsRegion || '',
    credentials: {
      accessKeyId: awsAccessKeyId || '',
      secretAccessKey: awsSecretAccessKey || '',
    }
  });

  const checkS3ImageExists = async (s3Key: string): Promise<boolean> => {
    try {
      await s3Client.send(new HeadObjectCommand({
        Bucket: awsS3Bucket,
        Key: s3Key
      }));
      return true;
    } catch (error) {
      return false;
    }
  };

  const fetchImageFromS3 = async (s3Url: string): Promise<string> => {
    try {
      // Extract key from S3 URL
      const urlParts = s3Url.split('/');
      const key = urlParts.slice(3).join('/');

      // Get object from S3
      const response = await s3Client.send(new GetObjectCommand({
        Bucket: awsS3Bucket!,
        Key: key
      }));

      // Convert stream to blob
      const arrayBuffer = await response.Body?.transformToByteArray();
      if (!arrayBuffer) {
        throw new Error('Failed to read image data from S3');
      }

      // Create blob from array buffer
      const blob = new Blob([arrayBuffer], { type: response.ContentType || 'image/png' });

      // Convert blob to base64
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            resolve(reader.result);
          } else {
            reject(new Error('Failed to convert image to base64'));
          }
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error fetching image from S3:', error);
      throw error;
    }
  };

  const blobToBase64 = (blob: Blob, contentType: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const base64ToBlob = async (base64Data: string): Promise<Blob> => {
    const response = await fetch(base64Data);
    return response.blob();
  };

  const uploadToS3 = async (imageData: string, s3Key: string): Promise<string> => {
    try {
      const imageBlob = await base64ToBlob(imageData);
      
      const uploadParams = {
        Bucket: awsS3Bucket!,
        Key: s3Key,
        Body: imageBlob,
        ContentType: 'image/png',
      };

      console.log('Uploading to S3 with params:', uploadParams);

      const response = await s3Client.send(new PutObjectCommand(uploadParams));
      console.log('S3 upload response:', response);

      const s3Url = `https://${awsS3Bucket}.s3.${awsRegion}.amazonaws.com/${s3Key}`;
      console.log('Uploaded image URL:', s3Url);

      return s3Url;
    } catch (error) {
      console.error('Error uploading to S3:', error);
      throw error;
    }
  };

  const getImageFromLocalStorage = (selection: string): string | null => {
    return localStorage.getItem(selection);
  };

  const alignImages = async (oldImage: string, newImage: string): Promise<{oldAligned: string, newAligned: string}> => {
    try {
      // Convert S3 URLs to base64
      const [oldBase64, newBase64] = await Promise.all([
        fetchImageFromS3(oldImage),
        fetchImageFromS3(newImage)
      ]);

      console.log("oldBase64",oldBase64);
      console.log("newBase64",newBase64);

      const response = await axios.post(`${imgAlignUrl}/align-images`, {
        old_image: oldBase64,
        new_image: newBase64,
        save_img: false,
        resizing: true
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
        maxRedirects: 5
      });

      if (!response.data || !response.data.aligned_old_image || !response.data.aligned_new_image) {
        throw new Error('Invalid response from alignment API');
      }

      return {
        oldAligned: response.data.aligned_old_image,
        newAligned: response.data.aligned_new_image
      };
    } catch (error) {
      console.error('Error in image alignment:', error);
      if (axios.isAxiosError(error)) {
        console.error('API error details:', error.response?.data);
      }
      throw error;
    }
  };
  const fetchImageUrl = async (selection: string): Promise<string> => {
    if (!selection) return '';
    setLoading(true);
    
    try {
    //   const [submission, page] = selection.split('-');
      const s3Key = `gollab/img_align/${selection}.png`;

      const imageExistsInS3 = await checkS3ImageExists(s3Key);
      if (imageExistsInS3) {
        return `https://${awsS3Bucket}.s3.${awsRegion}.amazonaws.com/${s3Key}`;
      }

      const storedUrl = getImageFromLocalStorage(selection);
      return storedUrl || '';

    } catch (error) {
      console.error('Error fetching image:', error);
      return '';
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const updateImages = async () => {
      if (!currentSelection || !compareSelection) return;

      try {
        setLoading(true);

        // Get current and compare selection URLs
        const leftUrl = await fetchImageUrl(currentSelection);
        const rightUrl = await fetchImageUrl(compareSelection);

        // const [currentSubmission] = currentSelection.split('-');
        // const [compareSubmission] = compareSelection.split('-');

        // Define S3 keys for aligned images
        const leftS3Key = `gollab/img_align/${currentSelection}.png`;
        const rightS3Key = `gollab/img_align/${compareSelection}.png`;

        // Check if aligned images exist in S3
        const [leftExists, rightExists] = await Promise.all([
          checkS3ImageExists(leftS3Key),
          checkS3ImageExists(rightS3Key)
        ]);

        if (leftExists && rightExists) {
          // Use existing aligned images from S3
          setLeftImage(`https://${awsS3Bucket}.s3.${awsRegion}.amazonaws.com/${leftS3Key}`);
          setRightImage(`https://${awsS3Bucket}.s3.${awsRegion}.amazonaws.com/${rightS3Key}`);
        } else {
          // Align images and upload to S3
          const { oldAligned, newAligned } = await alignImages(leftUrl, rightUrl);
          
          // Upload aligned images to S3
          const [newLeftUrl, newRightUrl] = await Promise.all([
            uploadToS3(oldAligned, leftS3Key),
            uploadToS3(newAligned, rightS3Key)
          ]);

          setLeftImage(newLeftUrl);
          setRightImage(newRightUrl);
        }
      } catch (error) {
        console.error('Error updating images:', error);
        // Fallback to unaligned images
        const leftUrl = await fetchImageUrl(currentSelection);
        const rightUrl = await fetchImageUrl(compareSelection);
        setLeftImage(leftUrl);
        setRightImage(rightUrl);
      } finally {
        setLoading(false);
      }
    };

    updateImages();
  }, [currentSelection, compareSelection]);

  return (
    <div className="space-y-8 px-6 py-4">
      <div className="mt-2">
        {/* {loading ? (
          <div className="items-center">Loading...</div>
        ) : (
          <>
            {compareSelection && ( */}
              <div style={{ position: "relative" }}>
                <TransformWrapper
                  initialScale={1}
                  minScale={1}
                  maxScale={5}
                  onTransformed={(e) => {
                    // Update zoom level when transformation occurs
                    const scale = e.state.scale;
                    setZoomLevel(Math.round(scale * 100));
                  }}
                >
                
                  {({ zoomIn, zoomOut, resetTransform }) => (
                    <>
                     {compareSelection && (                        
                       <div
                        style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                            zIndex: 10,
                            display: "flex",
                            gap: "8px",
                            alignItems: "center"
                        }}
                        >
                        <button 
                            type="button" 
                            className="px-2 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-emerald-700 focus:z-10 focus:ring-2 focus:ring-emerald-700 focus:text-emerald-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-emerald-500 dark:focus:text-white"
                            onClick={() => resetTransform()}
                            title="Fit to Screen"
                        >
                            <img
                            src={FitToScreenIcon}
                            alt="Fit to Screen"
                            />
                        </button>

                        <div className="inline-flex rounded-md shadow-sm" role="group">
                            <button 
                            type="button" 
                            className="px-2 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-emerald-700 focus:z-10 focus:ring-2 focus:ring-emerald-700 focus:text-emerald-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-emerald-500 dark:focus:text-white"
                            onClick={() => zoomIn()}
                            >
                            <img src={PlusIcon} alt="Plus icon" className="w-4 h-4" />
                            </button>
                            <button 
                            type="button" 
                            className="px-2 py-2 text-sm font-medium text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-emerald-700 focus:z-10 focus:ring-2 focus:ring-emerald-700 focus:text-emerald-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-emerald-500 dark:focus:text-white"
                            onClick={() => resetTransform()}
                            >
                            {zoomLevel}%
                            </button>
                            <button 
                            type="button" 
                            className="px-2 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-emerald-700 focus:z-10 focus:ring-2 focus:ring-emerald-700 focus:text-emerald-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-emerald-500 dark:focus:text-white"
                            onClick={() => zoomOut()}
                            >
                            <img src={MinusIcon} alt="Minus icon" className="w-4 h-4" />
                            </button>
                        </div>
                        </div>
                     )}

                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "10px",
                          zIndex: 10,
                        }}
                      >
                        <div className="grid grid-cols-1 gap-0">
                            <select
                                value={currentSelection}
                                onChange={(e) => setCurrentSelection(e.target.value)}
                                className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 font-medium text-neutral-700 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-emerald-600 sm:text-sm/6 px-5 py-3"
                            >                               
                                {selectionOptions.map((option) => (
                                <option key={`${option.submission}-${option.page}`} value={`${option.submission}-${option.page}`}>
                                    {option.label}
                                </option>
                                ))}
                            </select>
                            <img
                                src={ChevronBottomIcon}
                                alt="Chevron Bottom"
                                className="w-2 h-2 pointer-events-none col-start-1 row-start-1 mr-2 self-center justify-self-end text-gray-500"
                            />
                        </div>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          zIndex: 10,
                        }}
                      >
                        <div className="grid grid-cols-1 gap-0">
                            <select
                                value={compareSelection}
                                onChange={(e) => setCompareSelection(e.target.value)}
                                className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 font-medium text-neutral-700 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-emerald-600 sm:text-sm/6 px-5 py-3"
                            >
                                <option value="">Select Compare Submission</option>
                                {selectionOptions.map((option) => (
                                <option key={`${option.submission}-${option.page}`} value={`${option.submission}-${option.page}`}>
                                    {option.label}
                                </option>
                                ))}
                            </select>
                            <img
                                src={ChevronBottomIcon}
                                alt="Chevron Bottom"
                                className="w-2 h-2 pointer-events-none col-start-1 row-start-1 mr-2 self-center justify-self-end text-gray-500"
                            />
                        </div>
                      </div>
                      
                      <TransformComponent>
                        { compareSelection && (
                        <div style={{ width: "100%", height: "100%" }}>     
                          <ReactCompareSlider
                            handle={<CustomHandle />}
                            itemOne={
                              <ReactCompareSliderImage
                                src={leftImage}
                                alt="Before"
                                style={imageStyle}
                                onLoad={() => setLoaded(prev => prev + 1)}
                              />
                            }
                            itemTwo={
                              <ReactCompareSliderImage
                                src={rightImage}
                                alt="After"
                                style={imageStyle}
                                onLoad={() => setLoaded(prev => prev + 1)}
                              />
                            }
                          />
                        </div>
                        )}
                      </TransformComponent>                      
                    </>
                  )}
                </TransformWrapper>
              </div>
           {/* )}
          </>
        )} */}
      </div>
    </div>
  );
};

export default Comparison;